<template>
    <div v-if="dataSource && dataSourceType === 'ProtocolDocument'"
        :key="document.id"
        class="p-relative"
    >
        <Toolbar 
            :is-list="false"
            v-on:toolbar-button-click="onToolbarClick"  
        />

        <DocumentPanel :document-info="dataSource.Data.Object" />
        
        <v-tabs
            v-model="tab"
            background-color="transparent"
            slider-size="3"
            class="tab-selector-panel"
            mobile-breakpoint="0"
        >
            <v-tabs-slider color="#E43958"></v-tabs-slider>

            <v-tab 
                v-for="item in pages"
                :key="document.id + item.FormId"
                @click="onTabClicked(item.FormId)"
            >
                <template v-if="item.Count < 0">
                    {{ item.Name }}
                </template> 
                <template v-else>
                    {{ item.Name }}
                    <span class="count" id="attachCounter">{{item.Count}}</span>
                </template>   
            </v-tab>

        </v-tabs>

        <v-tabs-items
            v-model="tab"
            class="tab-content-panel"
        >
            <v-tab-item 
                v-for="item in pages"
                :key="document.id + item.FormId"
            >
                <template v-if="item.FormId == document.id">
                    <v-form class="wrapperForm" ref="form" lazy-validation>
                        <v-row dense>

                            <v-col cols="12" sm="12" md="6" class="section-wrapper">

                                <!--Блок Сведения о регистрации-->
                                <v-card flat>
                                    <v-card-text>
                                        <div class="form-box-title">{{$t("Сведения_о_регистрации")}}</div>
                                        
                                        <!--Поле Журнал регистрации-->
                                        <v-row no-gutters v-if="isOwnDocument && isRegistered">
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Журнал_регистрации")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="onlyReadData">
                                                    {{ document.Counter ? document.Counter.Name : '' }}
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <!--Поле Номенклатура-->
                                        <v-row no-gutters v-if="isOwnDocument">
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Номенклатура")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{document.Nomenclature ? `${document.Nomenclature.Code} ${document.Nomenclature.Header}` : $t("Не_указано")}}
                                                </div>
                                                <v-select
                                                    v-else
                                                    :value="document.NomenclatureId != guidEmpty ? document.NomenclatureId : null"
                                                    @input="onNomenclatureInput($event)"
                                                    :items="nomenclatures"
                                                    :item-text="item => `${item.Code} ${item.Header}`"
                                                    item-value="id"
                                                    return-object
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    :rules="requiredRule"
                                                    append-icon="fas fa-chevron-down"
                                                    :menu-props="{ bottom: true, offsetY: true }"
                                                >
                                                    <template v-slot:prepend-item>
                                                        <v-list-item>
                                                            <v-list-item-content>
                                                                <v-text-field 
                                                                    :placeholder="$t('Поиск')"
                                                                    class="mb-2 autocomplete-search"
                                                                    hide-details
                                                                    dense
                                                                    clearable
                                                                    autofocus
                                                                    v-model="nomenclatureSearch"
                                                                >
                                                                </v-text-field>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>

                                        <!--Поле Номер регистрации, дата-->
                                        <v-row no-gutters v-if="!document.is_new_record && isViewMode">
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Номер_регистрации,_дата")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="onlyReadData">{{ concatedRegistrationData  }}</div>
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters v-if="!isViewMode">
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Номер_регистрации")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <v-text-field
                                                    v-model.trim="RegNumber"
                                                    dense
                                                    outlined
                                                    :placeholder="$t('Рег._№')"
                                                    single-line
                                                    hide-details
                                                    rquired
                                                    :rules="requiredRule"
                                                    maxlength="20"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters v-if="!isViewMode">
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Дата_регистрации")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <v-menu
                                                    v-model="RegDateMenu"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            :value="RegDate | formattedDate"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            outlined
                                                            dense
                                                            rquired
                                                            :rules="requiredRule"
                                                            class="datepick-input"
                                                            hide-details
                                                        >
                                                        </v-text-field>
                                                    </template>

                                                    <v-date-picker
                                                        v-model="RegDate"
                                                        @input="RegDateMenu = false"
                                                        color="teal"
                                                        :min="minRegDate"
                                                        :max="maxRegDate"
                                                        :first-day-of-week="1"
                                                    >
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>

                                        <!--Поле Регистратор-->
                                        <v-row no-gutters v-if="isRegistered">
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Регистратор")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="onlyReadData">
                                                    <v-workplace-chip :id="document.Card.RegistratorWorkplaceId" :name="document.Card.RegistratorName" />
                                                </div>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>
                                
                                <!--Блок Реквизиты документа-->
                                <v-card flat>
                                    <v-card-text>

                                        <div class="form-box-title">{{$t("Реквизиты_документа")}}</div>

                                        <!--поле Вид документа-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Тип_документа") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="onlyReadData">
                                                    {{getDocumentType}}
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <!--поле Тип протокола-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Тип_протокола") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ document.Card.ProtocolType ? document.Card.ProtocolType.Value : $t("не_указано") }}
                                                </div>
                                                <v-select
                                                    v-else
                                                    :value="document.Card.ProtocolType"
                                                    @input="update({ property: 'Data.Object.Document.Card.ProtocolType', value: $event})"
                                                    :items="protocolTypeItems"
                                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                                    item-value="id"
                                                    return-object
                                                    hide-details
                                                    outlined
                                                    dense
                                                    required
                                                    :rules="requiredRule"
                                                    append-icon="fas fa-chevron-down"
                                                    :menu-props="{ bottom: true, offsetY: true }"
                                                >
                                                    <template v-slot:prepend-item>
                                                        <v-list-item>
                                                            <v-list-item-content>
                                                                <v-text-field 
                                                                    :placeholder="$t('Поиск')"
                                                                    class="mb-2 autocomplete-search"
                                                                    hide-details
                                                                    dense
                                                                    clearable
                                                                    autofocus
                                                                    v-model="protocolTypeSearch"
                                                                >
                                                                </v-text-field>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>

                                        <!--поле Характер вопроса-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Характер_вопроса")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ document.Card.QuestCharacter ? document.Card.QuestCharacter.Value : $t("не_указано") }}
                                                </div>
                                                <v-select
                                                    v-else
                                                    :value="document.Card.QuestCharacter"
                                                    @input="update({ property: 'Data.Object.Document.Card.QuestCharacter', value: $event })"
                                                    :items="questCharacterItems"
                                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                                    item-value="id"
                                                    return-object
                                                    hide-details
                                                    outlined
                                                    dense
                                                    append-icon="fas fa-chevron-down"
                                                    :menu-props="{ bottom: true, offsetY: true }"
                                                >
                                                    <template v-slot:prepend-item>
                                                        <v-list-item>
                                                            <v-list-item-content>
                                                                <v-text-field 
                                                                    :placeholder="$t('Поиск')"
                                                                    class="mb-2 autocomplete-search"
                                                                    hide-details
                                                                    dense
                                                                    clearable
                                                                    autofocus
                                                                    v-model="questCharacterSearch"
                                                                >
                                                                </v-text-field>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>

                                        <!--поле Язык документа-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Язык_документа")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">

                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{document.Card.Languages ? documentLanguages.map((x) => x.Value).join(', ') : $t("Не_указано")}}
                                                </div>

                                                <v-autocomplete
                                                    v-else
                                                    v-model="documentLanguages"
                                                    :items="GetReference(1000)"
                                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                                    item-value="id"
                                                    multiple
                                                    return-object
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    :rules="multipleRule"
                                                    append-icon="fas fa-chevron-down"
                                                >
                                                </v-autocomplete>

                                            </v-col>
                                        </v-row>

                                        <!--поле Тип носителя-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Тип_носителя")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">

                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{document.Card.CarrierType ? document.Card.CarrierType.Value : $t("Не_указано")}}
                                                </div>
                                                <v-autocomplete
                                                    v-else
                                                    :value="document.Card.CarrierType"
                                                    @input="update({ property: 'Data.Object.Document.Card.CarrierType', value: $event })"
                                                    :items="GetReference(1024)"
                                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                                    item-value="id"
                                                    return-object
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    :rules="requiredRule"
                                                    append-icon="fas fa-chevron-down"
                                                >
                                                </v-autocomplete>
                                            </v-col>
                                        </v-row>

                                        <!--поле Количество ЛПЭ-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Количество_листов/приложений") }}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">

                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{`${document.Card.ListCount}/${document.Card.AttachCount}`}}
                                                </div>
                                                <div class="more-inputs-in-row" v-else>
                                                    <v-text-field
                                                        :value="document.Card.ListCount"
                                                        @input="update({ property: 'Data.Object.Document.Card.ListCount', value: $event })"
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                        type="number"
                                                        :rules="integerNotNull"
                                                        class="cust-inputnumber"
                                                        min="0"
                                                    >
                                                    </v-text-field>
                                                    <span>/</span>
                                                    <v-text-field
                                                        :value="document.Card.AttachCount"
                                                        @input="update({ property: 'Data.Object.Document.Card.AttachCount', value: $event })"
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                        type="number"
                                                        :rules="integerRule.concat(requiredRule)"
                                                        class="cust-inputnumber"
                                                        min="0"
                                                    >
                                                    </v-text-field>
                                                </div>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>
                            </v-col>
                            
                            <v-col cols="12" sm="12" md="6" class="section-wrapper">

                                <template v-if="!document.is_new_record && isRegistered">
                                    <!--ToDo Панелька статуса документа-->
                                    <DocumentStatusCard 
                                        :controlInfo="controlInfo"
                                    />
                                </template>

                                <!--Секция Корреспондент-->
                                <v-card flat>
                                    <v-card-text>
                                        <div class="form-box-title">{{$t("Корреспондент")}}</div>

                                        <!--поле Отправитель-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Отправитель")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div class="icon-click-row-group icrg-align-center">                                                    
                                                    <div class="onlyReadData">
                                                        <v-enterprise-chip v-if="document.Card.Correspondent.Name" :name="document.Card.Correspondent.Name" />
                                                        <div class="chipAreaNoData" v-if="!document.Card.Correspondent.Name"></div>
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <!--Поле Исполнитель-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Исполнитель")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="icon-click-row-group icrg-align-center">                                                    
                                                    <div v-if="!isViewMode" @click="onExecuterSelect">
                                                        <v-icon small v-tooltip.left-center="$t('Выбрать_исполнителя')">
                                                            fas fa-edit
                                                        </v-icon>
                                                    </div>
                                                    <div class="onlyReadData">
                                                        <v-workplace-chip 
                                                            v-if="document.Card.ExecuterName" 
                                                            :id="document.Card.ExecuterWorkplaceId" 
                                                            :name="document.Card.ExecuterName" 
                                                            :canDelete="!isViewMode"                                                            
                                                            :delFunc="onRemoveExecuter"
                                                        />
                                                    </div>
                                                    <div class="chipAreaNoData d-flex align-center pl-1" v-if="!document.Card.ExecuterName && !isViewMode">
                                                        <v-select-members-chip                                            
                                                            target-type="Member"
                                                            @on-select="onSelecExecuterChip"
                                                            :items-params="membersExecuterSelectParams"
                                                            :required="true"
                                                        /> 
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <!--Поле Телефон исполнителя-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Телефон_исполнителя")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ document.Card.Phone ? document.Card.Phone : $t("Не_указано") }}
                                                </div>
                                                <v-text-field
                                                    v-else
                                                    :value="document.Card.Phone"
                                                    @input="update({ property: 'Data.Object.Document.Card.Phone', value: $event })"
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                >
                                                </v-text-field>
                                            </v-col>
                                        </v-row>

                                        <!--Поле Подписал-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Подписал")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ document.Card.SignerName ? document.Card.SignerName : $t("Не_указано") }}
                                                </div>
                                                <v-text-field
                                                    v-else
                                                    :value="document.Card.SignerName"
                                                    @input="update({ property: 'Data.Object.Document.Card.SignerName', value: $event })"
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    :rules="requiredRule"
                                                >
                                                </v-text-field>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>
                            </v-col>

                            <v-col cols="12" sm="12" md="12" class="section-wrapper">

                                <!--Секция Краткое содержание-->
                                <v-card flat>
                                    <v-card-text>
                                        <div class="form-box-title">{{$t("Краткое_содержание")}}</div>

                                        <!--Поле Краткое содержание-->
                                        <v-row class="full-width-row" no-gutters>
                                            <v-col cols="12" sm="12" md="2">
                                                <label class="f-label">{{$t("Краткое_содержание")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="10">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ document.Theme }}
                                                </div>
                                                <v-textarea v-else
                                                    :value="document.Theme"
                                                    @input="update({ property: 'Data.Object.Document.Theme', value: $event })"
                                                    rows="3"
                                                    no-resize
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    :rules="requiredRule"
                                                >
                                                </v-textarea>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>

                            </v-col>

                        </v-row>
                    </v-form>
                </template>

                <template v-else-if="!!item && item.FormId == 'Control'">
                    <ControlTab
                        :document="document"
                        :ref="item.FormId"
                    />
                </template>

                <template v-else-if="!!item">
                    <component
                        :is="$mapComponent(item.FormId)" 
                        :ref="item.FormId" 
                        :id="document.id"
                    />
                </template>

            </v-tab-item>

        </v-tabs-items>
        <SendForExecuteDlg v-if="isSendForExecuteDlgVisible" />
    </div>
</template>

<script>
import i18n from '@/i18n'
import sys from '@/services/system';
import { mapActions, mapGetters } from 'vuex';
import Toolbar from '@/components/documents/Toolbar.vue';
import DocumentPanel from '@/components/documents/DocumentPanel.vue';
import DocumentStatusCard from '@/components/documents/DocumentStatus';
import ControlTab from '@/components/documents/tabs/static/Control';
import _ from 'lodash';
import draggable from 'vuedraggable'

export default {
    name: "ProtocolCard",
    components: {
        Toolbar,
        DocumentPanel,
        DocumentStatusCard,
        ControlTab,
        SendForExecuteDlg: () => import('@/components/orders/dialogs/SendForExecutionDlg'),
        draggable
    },
    asyncComputed: {
        questCharacterItems: {
            async get () {
                let items = Array.from(await this.$store.dispatch('documents/references/getReference', { id: 1003 }));

                if (this.questCharacterSearch)
                    return items.filter(i => sys.checkSearch(i.Value, this.questCharacterSearch) || i.id == this.document?.Card?.QuestCharacter?.id);
                else
                    return items;
            },
            default: [],
            watch: ['questCharacterSearch']
        },
        protocolTypeItems: {
            async get () {
                let items = Array.from(await this.$store.dispatch('documents/references/getReference', { id: 1035 }));

                if (this.protocolTypeSearch)
                    return items.filter(i => sys.checkSearch(i.Value, this.protocolTypeSearch) || i.id == this.document?.Card?.ProtocolType?.id);
                else
                    return items;
            },
            default: [],
            watch: ['protocolTypeSearch']
        },
        nomenclatures : {
            async get() {
                if (this.nomenclatureSearch) {                
                    return (await this.$store.dispatch('documents/references/getNomenclatures', { forDepartment: false }))
                        .map(x => ({ id: x[0], Code: x[1], Header: x[2] }))
                        .filter(i => sys.checkSearch(`${i.Code} ${i.Header}`, this.nomenclatureSearch) || i.id == this.nomenclature?.id);
                }
                else
                    return (await this.$store.dispatch('documents/references/getNomenclatures', { forDepartment: false }))
                        .map(x => ({ id: x[0], Code: x[1], Header: x[2] }));
            },
            default: [],
            watch: ['nomenclatureSearch']
        }
    },
    computed: {
        ...mapGetters('orders/dialogs/sendForExecution', {isSendForExecuteDlgVisible: 'isVisible'}),
        ...mapGetters('documents/references', ['GetReference']),
        ...mapGetters('global/auth', { userEnterpriseId: 'getUserEnterpriseId' }),
        ...mapGetters('global/actionsource', { controlInfo: 'getControlInfo', dataSource: 'getDataSource', dataSourceType: 'getDataSourceType' }),
        ...mapGetters({ 
            requiredRule: 'getRequiredRule',
            multipleRule: 'getMultipleRule',
            integerRule: 'getIntegerRule',
            integerNotNull: 'getIntegerNotNullRule'
        }),
        isOwnDocument() {
            if (this.userEnterpriseId != null)
                return this.userEnterpriseId === this.document.EnterpriseId;

            return true;
        },
        isViewMode() {
            return this.dataSource.Data.FormId == "0201141";
        },
        document() {
            return this.dataSource?.Data?.Object?.Document;
        },
        getDocumentType(){
            if (this.document.Card.DocumentType?.UniValue)
                if (this.$i18n.locale === 'ru')
                    return this.document.Card.DocumentType.UniValue["ru-RU"];
                else
                    return this.document.Card.DocumentType.UniValue["kk-KZ"];
            return  i18n.t("не_указано");
        },
        guidEmpty() {
            return sys.guidEmpty();
        },
        pages() {
            let pages = 
            [
                { Count: -1, FormId: this.document?.id, Name: i18n.t("Карточка") }
            ];

            if (!this.document?.is_new_record)
                pages.push({ Count: -1, FormId: "Control", Name: i18n.t("Контроль") });

            pages = pages.concat(this.dataSource?.Data?.Object?.Pages ?? []);

            //если документ является пунктом убираем вкладку Вложения
            if (this.document?.Card.TypeC4 === 3)
                pages = pages.filter( x => x.FormId != "0202010" )

            return pages;
        },
        concatedRegistrationData() {
            let result = i18n.t("Не_указано");

            if (this.document.Card.RegNumber?.length)
                result = `№ ${this.document.Card.RegNumber}`;

            if (this.document.Card.RegDate)
                result += ` ${this.$t("от_время")} ${this.formatDate(this.document.Card.RegDate)} г.`;

            return result;
        },
        documentLanguages: {
            get: function() {
                return this.document.Card.Languages?.data_list ?? [];
            },
            set: function(newValue) {
                if (this.document.Card.Languages != null)
                    this.updateSource({ property: 'Data.Object.Document.Card.Languages.data_list', value: newValue });
                else
                    this.updateSource({ property: 'Data.Object.Document.Card.Languages', value: { data_list: newValue } });
            }
        },
        //#region регистрационные данные
        isRegistered() {
            return this.document.RegState == 1 || this.document.RegState == 4;
        },
        membersExecuterSelectParams(){
            return {
                includeInner: true,
                includeOuter: this.isORD === true,
                multiple: false,
                selected: [ this.document.Card.ExecuterWorkplaceId ]
            };
        },
        RegDate: {
            get: function() {
                if (this.document.Card.RegDate)
                    return this.$moment(this.document.Card.RegDate).format('YYYY-MM-DD');
                
                return null;
            },
            set: function(newValue) {
                this.updateSource({ property: 'Data.Object.Document.Card.RegDate', value: `/Date(${Date.parse(newValue)})/` });
            }
        },
        RegNumber: {
            get: function() {
                    return this.document.Card.RegNumber;
            },
            set: function(newValue) {
                this.updateSource({ property: 'Data.Object.Document.Card.RegNumber', value: newValue });
            }
        },
        minRegDate() {
            return null;
        },
        maxRegDate() {
            return this.$moment(new Date()).format('YYYY-MM-DD');
        },
        //#endregion
    },
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    data () {
        return {
            tab: null,
            //для выбора даты исходящего в режиме редактирования
            RegDateMenu: false,
            //для выбора корреспондента
            correspondentName: null,
            questCharacterSearch: "",
            nomenclatureSearch: "",
            protocolTypeSearch: "",
        }
    },
    methods: {
        ...mapActions('global/actionsource', { updateSource: 'updateDataSource' }),
        //обработка клика по вкладкам документа
        onTabClicked (formId) {
            if (formId === this.document?.id) return;

            let component = this.$refs[formId]?.[0];

            if (component)
                if (typeof component.update === 'function')
                    component.update();
        },
        //обработка клика по тулбару и маппинг в действия хранилища
        async onToolbarClick (event, button) {
            let actions = Reflect.ownKeys(this.$store._actions).filter(i => i.includes('global/actionsource/')).map(i => {return i.split('/')[2]})
            
            if (actions.includes(button.Action)) {
                if(button.Action == 'Save' || button.Action == 'SaveAndClose'){
                    let validate_result = this.$refs.form[0].validate();
                    if(validate_result){
                        await this.$store.dispatch(`global/actionsource/${button.Action}`, {event, button});        
                    }else{
                        this.$notify.alert(i18n.t("Не_заполнены_обязательные_поля"));
                    }
                }
                else
                {
                    await this.$store.dispatch(`global/actionsource/${button.Action}`, {event, button});
                }
            }
            else
                this.$notify.alert(`Действие_${button.Action}_не_реализовано.`);
        },
        onNomenclatureInput(event) {
            this.updateSource({ property: 'Data.Object.Document.Nomenclature', value: { Code: event.Code, Header: event.Header } });
            this.updateSource({ property: 'Data.Object.Document.NomenclatureId', value: event.id });
        },
        async onExecuterSelect (){
            try {
                let selectMemberParams =  { 
                    title: i18n.t("Выбор_исполнителя"),
                    includeInner: true,
                    includeOuter: this.isORD === true,
                    multiple: false,
                    selected: [ this.document.Card.ExecuterWorkplaceId ]
                }
                let { workplaceId, employeeId, name, contacts } = await this.$store.dispatch('global/dialogs/selectMembersNew/open', selectMemberParams);          
                this.onSelecExecuterChip({value: { name, workplaceId, employeeId, contacts } }); 
                /*this.updateSource({ property: 'Data.Object.Document.Card.ExecuterName', value: name });
                this.updateSource({ property: 'Data.Object.Document.Card.ExecuterWorkplaceId', value: workplaceId });
                this.updateSource({ property: 'Data.Object.Document.Card.ExecuterId', value: employeeId });

                let phone = contacts?.find(contact => { return contact.type === "phone"; }) ?? null;
                this.updateSource({ property: 'Data.Object.Document.Card.Phone', value: phone?.value ?? null });*/
            }
            catch (ex) {
                console.log(ex);
            }
        },
        onSelecExecuterChip(e){
            this.updateSource({ property: 'Data.Object.Document.Card.ExecuterName', value: e.value.name });
            this.updateSource({ property: 'Data.Object.Document.Card.ExecuterWorkplaceId', value: e.value.workplaceId });
            this.updateSource({ property: 'Data.Object.Document.Card.ExecuterId', value: e.value.employeeId });

            let phone = e.value.contacts?.find(contact => { return contact.type === "phone"; }) ?? null;
            this.updateSource({ property: 'Data.Object.Document.Card.Phone', value: phone?.value ?? null });
        },
        onRemoveExecuter() {
            this.updateSource({ property: 'Data.Object.Document.Card.ExecuterName', value: null });
            this.updateSource({ property: 'Data.Object.Document.Card.ExecuterWorkplaceId', value: '00000000-0000-0000-0000-000000000000' });
            this.updateSource({ property: 'Data.Object.Document.Card.ExecuterId', value: '00000000-0000-0000-0000-000000000000' });                
            this.updateSource({ property: 'Data.Object.Document.Card.Phone', value: null });
        },
        formatDate (source) {
            return this.$moment(source).format('DD.MM.YYYY');
        },        
        update: _.debounce(function (object) {
            this.updateSource(object);
        }, 250)
    },
    async created() {        
        (async() => {
            while(typeof this.$refs.form === 'undefined')
                await new Promise(resolve => setTimeout(resolve, 100));

            this.$refs?.form?.[0]?.validate();
        })();
    },
    updated() {
        if (this.dataSource !== null) {
            this.$refs?.form?.[0]?.validate();
        }  
    },
    mounted() {        
        this.$eventBus.$on('set-active-tab', async ({ FormId }) => {
            let page = this.pages.find(x => x.FormId === FormId);

            if (page) {
                let targetIndex = this.pages.indexOf(page);

                if (this.tab != targetIndex)
                    this.tab = targetIndex;
                else
                    this.$eventBus.$emit('update-active-tab');
            }
        });
    },
    beforeDestroy() {
        this.$eventBus.$off('set-active-tab');
    }
}
</script>